<template>
    <div class="page-wrapper page-home">
        <!-- Nav Heading Spacer -->
        <div class="header-spacer"></div>

        <!-- Evenements Listing -->
        <section class="evenements-listing">
            <div class="top site-max-width-large" v-if="contentIsLoaded">
                <h2 class="big-title title" data-inview="fadeInUp" data-delay="200">
                    {{ content.page.entete.titre }}
                </h2>
                <h3 class="small-title float-label" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.page.entete.texte"></span>
                </h3>
            </div>

            <div class="grid site-max-width-large" v-if="pageIsLoaded">
                <router-link to="/evenements/evenement-corporatif" class="row" :class="'theme-' + globals.evenementsEntry.data[0].page.couleurTitre.value">
                    <div class="col-one" :class="'theme-' + globals.evenementsEntry.data[0].page.couleurTitre.value">
                        <h3 v-html="globals.evenementsEntry.data[0].page.titres.titre1"></h3>
                    </div>
                    <div class="col-two">
                        <div class="img-wrap">
                            <img class="main" :src="globals.evenementsEntry.data[0].page.imageSimple" :alt="globals.evenementsEntry.data[0].page.title" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/evenements/mariage" class="row" :class="'theme-' + globals.evenementsEntry.data[1].page.couleurTitre.value">
                    <div class="col-one">
                        <div class="img-wrap">
                            <img class="main" :src="globals.evenementsEntry.data[1].page.imageSimple" :alt="globals.evenementsEntry.data[1].page.title" />
                        </div>
                    </div>
                    <div class="col-two top-left" :class="'theme-' + globals.evenementsEntry.data[1].page.couleurTitre.value">
                       <h3 v-html="globals.evenementsEntry.data[1].page.titres.titre1"></h3>
                    </div>
                </router-link>

                <router-link to="/evenements/événements-privés" class="row" :class="'theme-' + globals.evenementsEntry.data[2].page.couleurTitre.value">
                    <div class="col-one" :class="'theme-' + globals.evenementsEntry.data[2].page.couleurTitre.value">
                        <h3 v-html="globals.evenementsEntry.data[2].page.titres.titre1"></h3>
                    </div>
                    <div class="col-two">
                        <div class="img-wrap">
                            <img class="main" :src="globals.evenementsEntry.data[2].page.imageSimple" :alt="globals.evenementsEntry.data[2].page.title" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/evenements/lancement-de-produit" class="row reverse" :class="'theme-' + globals.evenementsEntry.data[3].page.couleurTitre.value">
                    <div class="col-one">
                        <div class="img-wrap">
                            <img class="main" :src="globals.evenementsEntry.data[3].page.imageSimple" :alt="globals.evenementsEntry.data[3].page.title" />
                        </div>
                    </div>
                    <div class="col-two top-right" :class="'theme-' + globals.evenementsEntry.data[3].page.couleurTitre.value">
                        <h3 v-html="globals.evenementsEntry.data[3].page.titres.titre1"></h3>
                    </div>
                </router-link>

                <router-link to="/evenements/expériences-culinaires" class="row reverse" :class="'theme-' + globals.evenementsEntry.data[4].page.couleurTitre.value">
                    <div class="col-one" :class="'theme-' + globals.evenementsEntry.data[4].page.couleurTitre.value">
                        <h3 v-html="globals.evenementsEntry.data[4].page.titres.titre1"></h3>
                    </div>
                    <div class="col-two">
                        <div class="img-wrap">
                            <img class="main" :src="globals.evenementsEntry.data[4].page.imageSimple" :alt="globals.evenementsEntry.data[4].page.title" />
                        </div>
                    </div>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
// import Flickity from 'flickity'
// import { gsap } from 'gsap'

import { mapState, mapGetters } from 'vuex'
import { initInViewAnimations } from '@/plugins/InViewAnimations'
// import EvenementsListing from '@/components/EvenementsListing'

export default {
    name: 'EvenementsListing',

    data() {
        return {}
    },

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()

                    setTimeout(() => {
                        const $eventsGrid = document.querySelector('.evenements-listing')
                        if ($eventsGrid) {
                            $eventsGrid.classList.add('is-in-view')
                        }
                    }, 2175)
                }
            },
            immediate: true,
        },
    },

    methods: {
        goTo(pos) {
            this.carousel.select(pos)
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
